import React from 'react'
import TextCorrectionComponent from '../components/TextCorrectionComponent';

const AboutUs = () => {
  return (

      <section className="section features-section overflow-hidden" id="aboutus">
        <div className="container">
                <div className="box-wrap">
                  <TextCorrectionComponent></TextCorrectionComponent>
                </div>
        </div>
      </section>
  );
};


export default AboutUs