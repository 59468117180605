import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';  // Import useLocation

const Nav = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();  // Permet de suivre la route actuelle

  // Fermer le menu automatiquement lors du changement de route
  useEffect(() => {
    setIsCollapsed(true);  // Fermer le menu à chaque changement de route
  }, [location]);  // Cette fonction est appelée chaque fois que la route change

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);  // Toggle entre ouvert et fermé
  };

  const closeNavbar = () =>{
    setIsCollapsed(true);
  };



  return (

      <nav className="navbar navbar-expand-lg fixed-top custom-nav sticky">
      <div className="container">
        {/* MENU OVERLAY */}
        <div className={`menu-overlay ${!isCollapsed ? 'visible' : ''}`}></div>

        {/* MENU CLOSE ICON */}
        {!isCollapsed && (
          <div className="menu-close-btn" onClick={closeNavbar}>
            <i className="mdi mdi-close-circle-outline"></i>
          </div>
        )}

        {/* LOGO */}
        <NavLink className="navbar-brand brand-logo mr-4" to="/">
          <img src="assets/images/logoofficielle.png" className="img-fluid newlogo" alt="" />
        </NavLink>

        {/* Navbar links */}
        <div
          className={`navbar-collapse collapse justify-content-center ${isCollapsed ? '' : 'show'}`}
          id="navbarCollapse"
        >
          <ul className="navbar-nav navbar-center" id="mySidenav">
            <li className="nav-item">
              <NavLink 
                to="/" 
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink 
                to="/FAQ" 
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                FAQ
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink 
                to="/ContactUs" 
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Kontakt
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Contact Us button and toggler */}
        <div className="contact_btn">
          <NavLink 
            to="/ContactUs" 
            className={({ isActive }) => `btn btn-sm ${isActive ? 'active' : ''}`}
          >
            CONTACT US
          </NavLink>
          <button
            className="navbar-toggler ml-2 p-0"
            type="button"
            onClick={toggleNavbar}
            aria-expanded={isCollapsed ? 'false' : 'true'}
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu"></i>
          </button>
        </div>
      </div>
      </nav>

  );
};

export default Nav;
