import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import React from "react";

const Footer = () => {
  return (
    <footer className="bg-body-tertiary text-center text-lg-start"
        style={{
        fontSize: "14px", // Taille de police réduite
        padding: "10px 0",
        backgroundColor: "rgba(0, 0, 0, 0.10)" // Réduction des paddings
        }}
    >
      {/* Grid container */}
      <div className="container p-4">
        {/* Grid row */}
        <div className="row">
          {/* Grid column */}
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase" style={{ fontSize: "12px", fontWeight: "bold" }}><a href="https://meinlehrer.com/ContactUs" className="text-body" style={{ textDecoration: "none" }}>Kontakt</a></h5>
          </div>
          {/* Grid column */}
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase mb-0" style={{ fontSize: "12px", fontWeight: "bold" }}><a href="https://meinlehrer.com/FAQ" className="text-body" style={{ textDecoration: "none" }}>Über uns</a></h5>
          </div>
          {/* Grid column */}
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase" style={{ fontSize: "12px", fontWeight: "bold" }}>Impressum</h5>
          </div>
          {/* Grid column */}
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase mb-0" style={{ fontSize: "12px", fontWeight: "bold" }}>Datenschutzerklärung</h5>
          </div>
          {/* Grid column */}
        </div>
        {/* Grid row */}
      </div>
      {/* Grid container */}
      {/* Copyright */}
      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)", fontSize:"12px",}}
      >
        © 2024 Copyright:{" "}
        <a className="text-body" href="https://meinlehrer.com/">
          Meinlehrer.com
        </a>
      </div>
      {/* Copyright */}
    </footer>
  );
};

export default Footer;
