import React from 'react';

const ContactUs = () => {
  return (
    <section className="contact-us-section" id="contactus">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-12 col-md-10">
              <p className="contac-message">Wenn Sie ein Problem haben oder Ihre Texte nicht korrigieren können oder wenn Sie andere Fragen haben, versuchen Sie, uns zu kontaktieren:</p>
              <h3 className="contact-email">mostafa.mouaden@gmail.com</h3>
              <p className="contact-thanks">Vielen Dank! Team Meinlehrer</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;






















































































































































/*import React from 'react'

const ContactUs = () => {
  return (
    <section className="section" id="contactus">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <p>Need Help</p>
              <h3>Quick Contact</h3>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="contact-bg mb-30 m-lg-0 wow fadeIn" data-wow-duration="1500ms">
              <div className="contact-box-inner">
                <div>
                  <h3>Do You Have Any Questions?</h3>
                  <p>
                    But we ipsum dolor sit amet consectetur adeisicing elit, sed do eiusmod tncididunt ut labore
                    et dolore magna aliqua
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <form className="contact-form wow fadeIn" data-wow-duration="1500ms">
              <h3>Please Fill Up The Form To Contact With Us</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <i className="input-icons mdi mdi-account-outline"></i>
                    <input type="text" className="form-control" name="name" placeholder="Your Full Name" required />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <i className="input-icons mdi mdi-email-outline"></i>
                    <input type="text" className="form-control" name="email" placeholder="Your Email " required />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <i className="input-icons mdi mdi-chevron-down"></i>
                    <select className="selectpicker form-control" name="service">
                      <option value="">Your Subject</option>
                      <option value="#">Query For Pricing</option>
                      <option value="">Query For Business</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <i className="input-icons mdi mdi-phone-outline"></i>
                    <input type="text" className="form-control" placeholder="Your Phone" name="phone" required />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <i className="textarea-icons mdi mdi-pencil-outline"></i>
                    <textarea
                      placeholder="Write Message"
                      className="form-control"
                      name="message"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button type="submit" className="btn theme-btn">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs*/