import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import React from "react";
import ReactDOM from "react-dom/client"; // Assurez-vous d'importer depuis "react-dom/client"
import App from "./App";
import "./style.css"

const root = ReactDOM.createRoot(document.getElementById("root")); // Crée un root React
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
