import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import FAQ from './pages/FAQ';
import Layout from './components/Layout';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
            <Route index element={<AboutUs/>} />
            <Route path="/FAQ" element={<FAQ/>} />
            <Route path="/ContactUs" element={<ContactUs/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
