import React from 'react'

const FAQ = () => {
  return (
      <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <p>Faq's</p>
              <h3>Frequently Asked</h3>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="wow fadeIn m-center-img" data-wow-duration="1500ms">
              <img src="assets/images/faqs.png" className="w-100" alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div id="accordion" className="faq-accordion-panel wow fadeIn" data-wow-duration="1500ms">
              <div className="question-card shadow-sm active">
                <div className="card-header p-0" id="headingOne">
                  <h4 className="mb-0">
                    <button
                      className="btn-link"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <span>1. Lorem Ipsum Dolor Sit?</span>
                      <span>
                        <i className="mdi mdi-menu-down caret-icon"></i>
                      </span>
                    </button>
                  </h4>
                </div>
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
              <div className="question-card shadow-sm">
                <div className="card-header p-0" id="headingTwo">
                  <h4 className="mb-0">
                    <button
                      className="btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span>2. Amet Consectetur Adipisicing?</span>
                      <span>
                        <i className="mdi mdi-menu-down caret-icon"></i>
                      </span>
                    </button>
                  </h4>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
              <div className="question-card shadow-sm">
                <div className="card-header p-0" id="headingThree">
                  <h4 className="mb-0">
                    <button
                      className="btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>3. Elit Sed Do Eiusmo?</span>
                      <span>
                        <i className="mdi mdi-menu-down caret-icon"></i>
                      </span>
                    </button>
                  </h4>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
              <div className="question-card shadow-sm">
                <div className="card-header p-0" id="headingFour">
                  <h4 className="mb-0">
                    <button
                      className="btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span>4. Ea Commodo Consequat?</span>
                      <span>
                        <i className="mdi mdi-menu-down caret-icon"></i>
                      </span>
                    </button>
                  </h4>
                </div>
                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ