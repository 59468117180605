import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Typewriter } from "react-simple-typewriter";


const TextCorrectionComponent = () => {
  const [texte, setTexte] = useState("");
  const [corrections, setCorrections] = useState("");
  const [loading, setLoading] = useState(false);
  const [niveau, setNiveau] = useState("");

  const handleCorrection = async () => {
    setLoading(true);
    setCorrections("");

    try {
      const response = await axios.post("https://api.meinlehrer.com/api/correct", {
        texte,
        niveau,
      });
      setCorrections(response.data.corrections);
    } catch (error) {
      console.error("Error during correction:", error);
      setCorrections("An error occurred during the correction.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 4 }}>
      <div style={{display: "flex",justifyContent: "center",alignItems: "center", marginBottom:"20px"}}>
        <p style={{fontWeight:"bold",margin:0,color:"black"}}>
          <Typewriter words={["Hallo 😁 ! Ich bin jetzt dein Deutschlehrer 🇩🇪 und helfe dir, deine Texte zu korrigieren. ✅",
          "Egal, wo du die Prüfung schreibst (Goethe-Institut, Telc oder ÖSD) und egal, welches Niveau du hast (A1,A2,B1,B1...) – ich bin immer für dich da 🚀 .",
          ]}
          loop={0}
          cursor
          cursorStyle="|"
          typeSpeed={60}
          delaySpeed={2000}
          deleteSpeed={50}>

          </Typewriter>
        </p>
      </div>
      {/* Titre principal */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#8A3EAF" }}
      >
        Hier können Sie Schreiben-Aufgaben korrigieren.
      </Typography>

        {/* Zone de saisie de texte */}
        <TextField
          label="Geben Sie Ihren Text ein, wählen Sie das Sprachniveau, und lassen Sie ihn korrigieren."
          variant="outlined"
          value={texte}
          onChange={(e) => setTexte(e.target.value)}
          multiline
          rows={15}
          fullWidth
          sx={{
            flex: 1,
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              backgroundColor: "#f9f9f9",
            },
            marginBottom: 2,
          }}
        />

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, alignItems: 'center', marginBottom: 2 }}>
      {/* Sélection du niveau de langue */}

      <FormControl fullWidth sx={{maxWidth:"250px", marginBottom: niveau ? 0 : 2,}}>
        <InputLabel>Sprachniveau</InputLabel>
        <Select
          value={niveau}
          onChange={(e) => setNiveau(e.target.value)}
          label="Niveau de langue"
          sx={{width:"100%"}}
        >
          {["A1", "A2", "B1", "B2", "C1", "C2"].map((level) => (
            <MenuItem key={level} value={level}>
              {level}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Button Correction */}
      {niveau && (
      <Button
        variant="contained"
        onClick={handleCorrection}
        fullWidth
        sx={{
          borderRadius: "8px",
          padding: "8px 15px",
          fontWeight: "bold",
          backgroundColor: "#8A3EAF",
          color: "white",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
          maxWidth:"250px",
          marginLeft:"auto",
          marginRight: "calc(50% - 125px)",
        }}
      >
        Text korrigieren
      </Button> 
      )}
      </Box>
      {/* Conteneur ajusté pour le responsive */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          alignItems: "flex-start",
          marginBottom: 3,
        }}
      >

        {/* Zone d'affichage des corrections */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "#f0f0f0",
            padding: 2,
            minHeight: "200px",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
            overflowY: "auto",
            whiteSpace:"pre-line",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : corrections ? (
            <Box>
              <Typography variant="h6">Korrigierter Text:</Typography>
              <div>{corrections}</div>
            </Box>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Die korrigierte Version des Textes wird hier angezeigt.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TextCorrectionComponent;
